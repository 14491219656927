<template>
  <div class="setting-container" v-if="isB2CUser">
    <div class="container-title is-hidden-tablet">
      Ajustes
    </div>
    <div class="router-tabs">
      <div
        v-for="(item, index) in routerItems"
        @click="goTo(item.url)"
        :key="index"
        :class="$router.currentRoute.name === item.url ? 'selected ' : ''"
        class="router-items"
      >
        {{ item.name }}
        <div :class="$router.currentRoute.name === item.url ? 'selected-mobile is-hidden-desktop' : 'is-hidden'"></div>
      </div>
    </div>
    <RouterView></RouterView>
  </div>
</template>

<script>
import router from '@/router';
import { mapGetters } from 'vuex';

import { clickBtnPorRuta } from '@/utils/analytics';

export default {
  name: 'Settings',
  data: () => ({
    routerItems: [
      { name: 'Método de pago', url: 'payment-methods', gtm: 'metodo_de_pago' },
      { name: 'Planes Disponibles', url: 'available-plans', gtm: 'planes_disponibles' },
    ],
  }),
  computed: {
    ...mapGetters('loginGeneral', ['isB2CUser', 'getPremiumAccount', 'getIsGolstats']),
  },
  methods: {
    goTo(route) {
      if (route !== router.currentRoute.name) {
        clickBtnPorRuta(this.routerItems.find(r => r.url === route).gtm, this.getPremiumAccount, this.getIsGolstats);
        router.push({ name: route });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.setting-container {
  display: flex;
  flex-flow: column;
  padding: 0px 60px;
  .container-title {
    font-family: Circular-Std-Bold;
    padding: 10px;
    border-bottom: solid 1px #cecece;
  }
  .router-tabs {
    height: 140px;
    margin-bottom: 20px;
    border-bottom: solid 1px #d5d5d5;
    display: flex;
    align-items: flex-end;
    justify-content: space-evenly;

    .router-items {
      font-family: Circular-Std-Book;
      font-size: 17px;
      letter-spacing: -0.09px;
      text-align: center;
      cursor: pointer;
      color: #494a4b;
      padding-bottom: 10px;
    }
    .router-items:hover {
      color: #2d84e8;
      font-family: Circular-Std-Bold;
    }
    .selected {
      font-family: Circular-Std-Bold;
    }
  }
}
@media screen and (max-width: 768px) {
  .setting-container {
    padding: 0 20px;
    .router-tabs {
      height: unset;
      padding-top: 10px;
      border-bottom: unset;
      .router-items {
        position: relative;
        .selected-mobile {
          position: absolute;
          bottom: 0px;
          width: 17px;
          height: 8px;
          border-radius: 6px;
          background: #060606;
          margin-left: auto;
          margin-right: auto;
          left: 0;
          right: 0;
        }
      }
    }
  }
}
@media screen and (max-width: 550px) {
  .setting-container {
    padding: 40px 10px;
    margin-top: -12px;
  }
}
</style>
